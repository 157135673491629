import { Result, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { EligibilityPreCheckResponseType, PastAttemptsType } from "../../../generated";
import { ApplicationHistory } from "../components/shared/application-history";
const { Paragraph, Link } = Typography;

interface RevalidationVerificationIneligiblePageProps {
  pastAttempts?: PastAttemptsType[];
  eligibilityPreCheck?: EligibilityPreCheckResponseType;
}
export const RevalidationVerificationIneligiblePage: FC<RevalidationVerificationIneligiblePageProps> = (props) => {
  const [eligibilityResponseCode, setEligibilityResponseCode] = useState<string>("");

  useEffect(() => {
    if (props.eligibilityPreCheck) {
      const responseCode = props.eligibilityPreCheck?.eligibility?.responseCode?.trim();
      setEligibilityResponseCode(responseCode ?? "");
    }
  }, [props.eligibilityPreCheck]);

  let selectMessage = () => {
    let title: string | JSX.Element = "You Are Currently Ineligible to Apply";
    let subTitle: string | JSX.Element = "";
    switch (eligibilityResponseCode) {
      case "CERT_VALID_INDEFINITELY":
        subTitle = (
          <Paragraph style={{ textAlign: "center" }}>You are already certified by ECFMG and your certificate is permanently valid.</Paragraph>
        );
        break;
      case "RESTRICTED":
        subTitle = (
          <Paragraph style={{ textAlign: "center" }}>
            Your account is currently restricted. Please{" "}
            <Link href={"https://www.ecfmg.org/contact.html"} target="_blank" rel="noreferrer">
              contact us
            </Link>{" "}
            for more information.
          </Paragraph>
        );
        break;
    }
    return { title: title, subtitle: subTitle };
  };

  return (
    <>
      <Result
        status="error"
        title={selectMessage().title}
        subTitle={
          <Paragraph style={{ display: "inline-block", marginBottom: "-30px" }}>
            <Paragraph
              style={{
                maxWidth: "400px",
                textAlign: "left",
                display: "inline-block",
                marginTop: "20px",
              }}
            >
              {selectMessage().subtitle}
            </Paragraph>
          </Paragraph>
        }
      />
      <br />
      <Row justify="center">{props.pastAttempts && props.pastAttempts.length > 0 && <ApplicationHistory pastAttempts={props.pastAttempts} />}</Row>
    </>
  );
};
