import { Spin, Typography } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicantPortalResponseType } from "../../generated";

const { Paragraph, Title } = Typography;

const RevalidationLimboMessages = ["RESTRICTED", "CERT_VALID_INDEFINITELY"];

interface RedirectAfterLoginRevalidationProps {
  applicantPortal: ApplicantPortalResponseType;
}
export const RedirectAfterLoginRevalidation: FC<RedirectAfterLoginRevalidationProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof props.applicantPortal !== "undefined") {
      if (props.applicantPortal?.finance?.payment) {
        //User has submitted payment
        if (props.applicantPortal?.finance?.payment?.transTime) {
          navigate("/qualification-complete");
        } else {
          navigate("/payment");
        }
      } else {
        checkEligible();
      }
    }
  }, []);

  const checkEligible = async () => {
    const eligibilityPreCheck = props.applicantPortal.eligibilityPreCheck;
    if (eligibilityPreCheck?.eligibility?.isEligible) {
      if (eligibilityPreCheck.lastScreenName) {
        navigate(`/${getNavigationAfterLastScreen(eligibilityPreCheck?.lastScreenName)}`);
      } else if (eligibilityPreCheck.isStep2CSFailed) {
        navigate(`/revalidation-verification-eligible-step2cs-failed`);
      } else if (eligibilityPreCheck.eligibility?.responseCode && RevalidationLimboMessages.includes(eligibilityPreCheck.eligibility?.responseCode)) {
        navigate(`/revalidation-verification-ineligible`);
      } else {
        navigate(`/revalidation-verification-eligible`);
      }
    } else {
      navigate(`/revalidation-verification-ineligible`);
    }
  };

  const getNavigationAfterLastScreen = (lastScreenName: string) => {
    switch (lastScreenName) {
      case "Pathway-1a":
        //if isCurrently licensed is true
        //then user has submitted for pathway1
        //take them to language assessment
        if (props.applicantPortal?.pathway1?.userResponse?.isCurrentlyLicensed) {
          return "Qualification-review";
        }
        //if it is false, user submitted data and left off at 1b
        else {
          return "QualificationB";
        }
      case "Pathway-1b":
        //if wasLicensedInRecentPast licensed is true
        //then user has submitted for pathway1
        //take them to language assessment
        if (props.applicantPortal?.pathway1?.userResponse?.wasLicensedInRecentPast) {
          return "Qualification-review";
        }
        //if it is false, user submitted data and left off at pathway 6
        else {
          return "Qualification6";
        }
      case "Pathway-6":
        return "Qualification-review";
      default:
        return "";
    }
  };

  return (
    <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
      <Spin size="large" />
      <Title level={2}>Checking Eligibility</Title>
    </Paragraph>
  );
};
