import { Card, Typography, Grid } from "antd";
import { FC } from "react";
import { PastAttemptsType } from "../../../../generated";
import { ApplicationHistoryListDesktop } from "./application-history-list-desktop";
import { ApplicationHistoryListMobile } from "./applicantion-history-list-mobile";

const { useBreakpoint } = Grid;
interface ApplicationHistoryProps {
  pastAttempts: PastAttemptsType[];
}

export const ApplicationHistory: FC<ApplicationHistoryProps> = (props) => {
  const screens = useBreakpoint();

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontSize: "15px", margin: 0 }}>
          Application History
        </Typography.Title>
      }
      style={{ width: "100%", maxWidth: "1500px", textAlign: "left" }}
      bodyStyle={screens.xs ? { padding: "5px" } : { padding: "10px" }}
    >
      {screens.xs ? (
        <ApplicationHistoryListMobile pastAttempts={props.pastAttempts} />
      ) : (
        <ApplicationHistoryListDesktop pastAttempts={props.pastAttempts} />
      )}
    </Card>
  );
};
