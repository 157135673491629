import { UserOutlined } from "@ant-design/icons";
import { Button, Result, Row, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PastAttemptsType } from "../../../generated";
import { ApplicationHistory } from "../components/shared/application-history";
const { Paragraph, Text, Link } = Typography;

export interface RevalidationVerificationEligibleStep2CSFailedPageProps {
  pastAttempts?: PastAttemptsType[];
}

export const RevalidationVerificationEligibleStep2CSFailedPage: FC<RevalidationVerificationEligibleStep2CSFailedPageProps> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Result
        icon={<UserOutlined style={{ color: "#61C8D7" }} />}
        title={<Typography.Title level={3}>You May Be Eligible to Pursue a 2025 Pathways Revalidation Application</Typography.Title>}
        extra={[
          <div style={{ maxWidth: "90%", margin: "0 auto 15px", textAlign: "left" }}>
            <Typography.Title level={4}>Important Notes:</Typography.Title>
            <Typography.Paragraph style={{ fontSize: 14, fontWeight: "normal" }}>
              Applicants with a previously accepted Pathways application from any Pathways season may be eligible to submit a Pathways Revalidation Application, 
              regardless of when their Pathway expires. However, please note that it is highly recommended that you complete the 2025 Pathways Revalidation Application <Typography.Text strong>only</Typography.Text> if:
              <br />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  you have an accepted 2021 or 2022 Pathway, or an ECFMG Certificate based on an accepted 2021 or 2022 Pathway, that will expire on December 31, 2024,
                   and you plan to participate in the 2025 NRMP Match
                </li>
              </ul>
              <ul style={{ listStyleType: "none"}}>
                <li>
                  <b>OR</b>
                </li>
              </ul>
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  you have an ECFMG Certificate based on a 2021 or 2022 Pathway that will expire on December 31, 2024, 
                  and you plan to enter a U.S. graduate medical education (GME) training program accredited by the Accreditation Council 
                  for Graduate Medical Education (ACGME) after December 31, 2024.  
                </li>
              </ul>

            </Typography.Paragraph>
            <Typography.Paragraph style={{ fontSize: 14, fontWeight: "normal" }}>
                If you are ECFMG certified and have already entered an ACGME-accredited training program or a non-standard program associated with an ACGME-accredited program, 
                and your ECFMG Certificate will expire before you complete the 12 months of clinical education required for your certificate to become valid indefinitely, 
                <Typography.Text strong> please{" "}
                <Link href={"https://www.ecfmg.org/contact.html"} target="_blank" rel="noreferrer">
                  contact us
                </Link>{" "}</Typography.Text>
                regarding the process for extending the validity of your ECFMG Certificate; <Typography.Text strong>do not use this Pathways Revalidation Application</Typography.Text> to extend the validity of your Certificate. See{" "}
                <Link href={"https://www.ecfmg.org/certification-pathways/permanent-validation.html"} target="_blank" rel="noreferrer">
                  Obtaining Permanent Validation of ECFMG Certificate
                </Link>{" "}for additional information.
              
            </Typography.Paragraph>
          </div>,
          // <br />,
          <div style={{ maxWidth: "90%", margin: "0 auto", textAlign: "left" }}>
            <br />
            <Typography.Text style={{ fontSize: 14 }}>
              ECFMG has determined that you currently meet the general eligibility requirements to pursue a Pathways Revalidation Application. You may
              continue with the application. Please note that if your eligibility changes after you submit this application, your application will be
              rejected, and you will not receive a refund.
            </Typography.Text>
            <br />
            <br />
            <Typography.Text style={{ fontSize: 14 }}>
              Please ensure that all the information you provide is complete and correct. Failure to provide complete and correct information can
              result in rejection of your application and/or a finding of{" "}
              <Link href={"https://www.ecfmg.org/resources/irregular-behavior.html"} target="_blank" rel="noreferrer">
                irregular behavior
              </Link>
              .
            </Typography.Text>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography.Title level={4}>You Must Apply to Pathway 6 to Meet ECFMG’s Requirements</Typography.Title>
            </div>
            <br />
            <Paragraph>
              You have failed Step 2 CS one or more times. In order to meet the clinical and communication skills requirements for ECFMG Certification,
              you are <Text strong>required</Text> to apply to Pathway 6: Evaluation of Clinical Patient Encounters by Licensed Physicians. Before
              proceeding with this application, you must review the{" "}
              <Link href={"https://www.ecfmg.org/certification-pathways/pathway-6.html"} target="_blank" rel="noreferrer">
                eligibility requirements and application information for Pathway 6
              </Link>
              .
            </Paragraph>
            <br />
            <Paragraph style={{ textAlign: "center" }}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  navigate(`/Qualification6`);
                }}
              >
                Continue
              </Button>
            </Paragraph>
          </div>,
        ]}
      />
      <br />
      <Row justify="center">
        {props.pastAttempts && props.pastAttempts.length > 0 && <ApplicationHistory pastAttempts={props.pastAttempts as PastAttemptsType[]} />}
      </Row>
    </>
  );
};
