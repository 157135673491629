import React, { FC, useState, useEffect } from "react";
import { Button, Row, Col, Space, Modal, Spin, Divider, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client/react";
import QualificationReviewCSExam from "./qualification-review-csExam";
import QualificationReviewCSExam2 from "./qualification-review-csExam-not-recent-past";
import QualificationReviewLicense from "./qualification-review-license";
import QualificationReviewEducation from "./qualification-review-education";
import QualificationReviewException from "./qualification-review-exception";
import { QualificationReviewAttestation } from "./qualification-review-attestation";
import QualificationReviewPathway6 from "./qualification-review-pathway6";
import useAuth from "../auth/use-auth";
import { getRoutingFlow, RoutingFlow } from "../constants";
import { Helmet } from "react-helmet";
import { ApplicantQualificationReviewSummaryDocument, A_CaseDetails, Pathway1, Pathway2, Pathway345, Pathway6 } from "../../generated";
import { RevalidationQualificationReviewAttestation } from "../revalidation/pages/revalidation-qualification-review-attestation-page";
const { Title, Paragraph, Text } = Typography;
const PATHWAY_NAME = {
  PathwayX: "PATHWAYX",
  Pathway1: "PATHWAY1",
  Pathway2: "PATHWAY2",
  Pathway3: "PATHWAY3",
  Pathway4: "PATHWAY4",
  Pathway5: "PATHWAY5",
  Pathway6: "PATHWAY6",
};

interface QualificationReviewProps {
  applicationType?: string;
}

const QualificationReview: FC<QualificationReviewProps> = (props) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [attestationVisible, setAttestationVisible] = useState(false);
  const [routingFlow, setRoutingFlow] = useState<RoutingFlow>(RoutingFlow.Login_Pw6);
  /* Retrieve data from db*/
  const { loading: loading2, data: data2 } = useQuery(ApplicantQualificationReviewSummaryDocument, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data2) {
      // display pw6 correctly
      const eligibilityResult = data2?.applicantPortal?.eligibilityPreCheck;
      setRoutingFlow(getRoutingFlow(eligibilityResult, data2.applicantPortal?.graduation)!);
    }
  }, [data2]);

  const showAttestation = () => {
    setAttestationVisible(true);
  };

  const onContinue = () => {
    navigate(`/payment`);
  };

  const onEditCSExam = () => {
    navigate(`/QualificationE`);
  };
  const onEditException = () => {
    navigate(`/QualificationI`);
  };
  const onEditLicense = () => {
    navigate(`/QualificationA`);
  };
  const onEditEducation = () => {
    navigate(`/QualificationF`);
  };

  const onEditPathway6 = () => {
    navigate(`/Qualification6`);
  };

  const GetPathwaySection = (pathway: string) => {
    //exception
    if (pathway === PATHWAY_NAME.PathwayX) {
      return !!data2?.applicantPortal?.pathwayX ? (
        <QualificationReviewException.Component onEditExceptionCallback={onEditException} initialValues={data2.applicantPortal.pathwayX} />
      ) : null;
    }
    //license - pathway1
    if (pathway === PATHWAY_NAME.Pathway1) {
      return !!data2?.applicantPortal?.pathway1 ? (
        <QualificationReviewLicense.Component onEditLicenseCallback={onEditLicense} initialValues={data2?.applicantPortal?.pathway1 as Pathway1} />
      ) : null;
    }
    //cs exam - pathway2
    if (pathway === PATHWAY_NAME.Pathway2) {
      return (
        <>
          {!!data2?.applicantPortal?.pathway1 ? (
            <QualificationReviewLicense.Component onEditLicenseCallback={onEditLicense} initialValues={data2.applicantPortal.pathway1 as Pathway1} />
          ) : null}
          {!!data2?.applicantPortal?.pathway2 ? (
            <QualificationReviewCSExam.Component onEditCSExamCallback={onEditCSExam} initialValues={data2.applicantPortal.pathway2 as Pathway2} />
          ) : null}
        </>
      );
    }
    //education - path345
    if (pathway === PATHWAY_NAME.Pathway3 || pathway === PATHWAY_NAME.Pathway4 || pathway === PATHWAY_NAME.Pathway5) {
      return (
        <>
          {!!data2?.applicantPortal?.pathway1 ? (
            <QualificationReviewLicense.Component onEditLicenseCallback={onEditLicense} initialValues={data2.applicantPortal.pathway1 as Pathway1} />
          ) : null}
          {!!data2?.applicantPortal?.pathway2 ? (
            <QualificationReviewCSExam2.Component onEditCSExamCallback={onEditCSExam} initialValues={data2.applicantPortal.pathway2 as Pathway2} />
          ) : null}
          {!!data2?.applicantPortal?.pathway345 && !!data2?.applicantPortal?.caseDetails ? (
            <QualificationReviewEducation.Component
              onEditEducationCallback={onEditEducation}
              initialValues={data2.applicantPortal.pathway345 as Pathway345}
              caseDetails={data2.applicantPortal.caseDetails as A_CaseDetails}
            />
          ) : null}
        </>
      );
    }
    if (pathway === PATHWAY_NAME.Pathway6) {
      if (routingFlow === RoutingFlow.Login_Pw6) {
        return !!data2?.applicantPortal?.pathway6 ? (
          <QualificationReviewPathway6.Component onEditPathway6Callback={onEditPathway6} initialValues={data2.applicantPortal.pathway6 as Pathway6} />
        ) : null;
      }
      return (
        <>
          {!!data2?.applicantPortal?.pathway1 ? (
            <QualificationReviewLicense.Component onEditLicenseCallback={onEditLicense} initialValues={data2.applicantPortal.pathway1 as Pathway1} />
          ) : null}
          {!!data2?.applicantPortal?.pathway2 ? (
            <QualificationReviewCSExam2.Component onEditCSExamCallback={onEditCSExam} initialValues={data2.applicantPortal.pathway2 as Pathway2} />
          ) : null}

          {routingFlow === RoutingFlow.Login_Pw12_2b_345_6 && props.applicationType !== "Revalidation" ? (
            <>
              <Divider plain orientation="left">
                <Space size="middle">
                  <Text strong>Pathway 3, 4, or 5</Text>
                  <Button onClick={onEditEducation}>Edit</Button>
                </Space>
              </Divider>
              <Paragraph>I do not meet the requirements for Pathway 3, 4, or 5.</Paragraph>
            </>
          ) : null}

          {!!data2?.applicantPortal?.pathway6 ? (
            <QualificationReviewPathway6.Component
              onEditPathway6Callback={onEditPathway6}
              initialValues={data2.applicantPortal.pathway6 as Pathway6}
            />
          ) : null}
        </>
      );
    }
  };

  const mainBody = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            {/* header */}
            <Row>
              <Col span={24}>
                <Title level={3}>Application Summary</Title>
              </Col>
            </Row>
            <Row>
              <Title level={5} style={{ paddingBottom: "15px" }}>
                Below is a summary of your application. Please carefully review the information you have provided before submission. This is your last
                opportunity to correct or change this information. Once submitted, it will become a part of your permanent Intealth record. Please
                print a copy of this page for your records before you click Continue.
              </Title>
            </Row>
            {GetPathwaySection(data2?.applicantPortal?.caseDetails?.currentPathway ?? "")}
          </Col>
        </Row>
        <Row className={"alignRightMedium"}>
          <Col span={24}>
            <Paragraph style={{ textAlign: "right" }}>
              <Space size="small">
                <Button id="qrev-continue-button" type="primary" size="large" onClick={showAttestation}>
                  Continue
                </Button>
              </Space>
            </Paragraph>
          </Col>
        </Row>

        <Modal title="Attestation" visible={attestationVisible} okText="Continue" width="80vw" footer={null} closable={false}>
          {props.applicationType === "Revalidation" ? (
            <RevalidationQualificationReviewAttestation onCancelCallback={() => setAttestationVisible(false)} onContinueCallback={onContinue} />
          ) : (
            <QualificationReviewAttestation onCancelCallback={() => setAttestationVisible(false)} onContinueCallback={onContinue} />
          )}
        </Modal>
      </>
    );
  };

  const missingRequiredData = () => {
    return (
      <Paragraph>
        <Paragraph>Looks like you haven't finished your application yet. Please log out and log back in.</Paragraph>
        <Button
          size="large"
          type="primary"
          key="console"
          onClick={() => {
            logout();
            navigate(`/`);
          }}
        >
          Log Out
        </Button>
      </Paragraph>
    );
  };

  const getReviewInfo = () => {
    if (loading2) {
      return <Spin />;
    }
    if (!data2?.applicantPortal?.caseDetails?.isRequiredDataProvided) {
      return <>{missingRequiredData()}</>;
    }
    return <>{mainBody()}</>;
  };

  return (
    <>
      <Helmet>
        <title>Qualification Review</title>
      </Helmet>
      {getReviewInfo()}
    </>
  );
};
export default QualificationReview;
