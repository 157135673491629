import { useQuery } from "@apollo/client";
import { Alert, Col, Layout, Row, Space, Typography, message } from "antd";
import axios from "axios";
import axiosRetry from "axios-retry";
import { useGA4React } from "ga-4-react";
import { FC, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import BillingInformation from "./components/BillingInformation";
import DocumentUploader from "./components/DocumentUploader";
import Login from "./components/Login";
import ViewPDF from "./components/ViewPDF";
import useAuth from "./components/auth/use-auth";
import { PATHWAYS, RoutingFlow, getRoutingFlow } from "./components/constants";
import HeaderContent from "./components/header-content";
import LoggedOut from "./components/logged-out";
import WarningPage from "./components/message-pages/warningPage";
import Qualification2b from "./components/pathway-questions/qualification-2b";
import Qualification6 from "./components/pathway-questions/qualification-6";
import Qualification6AddEncounters from "./components/pathway-questions/qualification-6-encounter-management";
import QualificationA from "./components/pathway-questions/qualification-a";
import QualificationB from "./components/pathway-questions/qualification-b";
import QualificationF from "./components/pathway-questions/qualification-f";
import QualificationG from "./components/pathway-questions/qualification-g";
import QualificationI from "./components/pathway-questions/qualification-i";
import PhysicianRequestLayout from "./components/physician-request/physician-request-layout";
import QualificationComplete from "./components/qualification-complete";
import QualificationReview from "./components/qualification-review/qualification-review";
import RedirectAfterLoginNew from "./components/redirect-after-login-new";
import { ScrollToTop } from "./components/scroll-to-top";
import SessionAlert from "./components/session-alert";
import SystemMaintenance from "./components/system-maintenance";
import VerificationEligible from "./components/verification-eligible";
import VerificationEligibleStep2CSFailed from "./components/verification-eligible-step2cs-failed";
import VerificationIneligible from "./components/verification-ineligible";
import FooterLayout from "./footer-layout";
import {
  ApplicantAppApplicantPortalDocument,
  ApplicantPortalResponseType,
  EligibilityPreCheckResponseType,
  ExceptionRequest,
  PastAttemptsType,
  Pathway1,
  Pathway2,
  Pathway345,
  Pathway6,
} from "./generated";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ECFMGLogo from "./logos/ECFMG_Horz_Full_Color_Division.svg";
import { RevalidationRoutes } from "./components/revalidation/revalidation-routes";
import { RedirectAfterLoginRevalidation } from "./components/revalidation/redirect-after-login-revalidation";

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

export enum ApplicationTypeEnum {
  New = "New",
  Revalidation = "Revalidation",
}

const App: FC<any> = (props) => {
  const screens = useBreakpoint();
  const [maintenanceMessage, setMaintenanceMessage] = useState<any>(undefined);
  const { userHasValidToken } = useAuth();
  let isAuthenticated = userHasValidToken();

  const { loading, error, data, refetch } = useQuery(ApplicantAppApplicantPortalDocument, {
    skip: isAuthenticated !== true && !props.isAuthenticatedFakeForStorybook,
    fetchPolicy: "network-only",
  });
  let isRendered = useRef(false); //Used to make Async code not get called on every render.

  const [routingFlow, setRoutingFlow] = useState<RoutingFlow>(RoutingFlow.Login_NotPw6);
  console.log(`isAuthenticated:${isAuthenticated}`);
  const maintenanceTypes = {
    NONE: "NONE",
    SYSTEM: "SYSTEM",
    ORACLE: "ORACLE",
    IMPENDING: "IMPENDING",
  };

  isRendered.current = true;

  const ga = useGA4React();
  const location = useLocation();

  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname);
    }
  }, [location, ga]);

  useEffect(() => {
    if (data) {
      // add restriction on routing
      const currentPathway = data?.applicantPortal?.caseDetails?.currentPathway;
      if (currentPathway && currentPathway !== PATHWAYS.Pathway6) {
        setRoutingFlow(RoutingFlow.Login_NotPw6);
      } else {
        const eligibilityResult = data?.applicantPortal?.eligibilityPreCheck;
        let flow = getRoutingFlow(eligibilityResult, data.applicantPortal?.graduation)!;
        setRoutingFlow(flow);
      }
    }
    if (typeof maintenanceMessage === "undefined") {
      getMaintenance();
    }
  }, [data]);

  const getMaintenance = async () => {
    const maintenanceEndpoint =
      process.env.REACT_APP_FUNCTION_ENDPOINT !== null && typeof process.env.REACT_APP_FUNCTION_ENDPOINT !== "undefined"
        ? `${process.env.REACT_APP_FUNCTION_ENDPOINT}api/paypal/maintenance`
        : "http://localhost:7071/api/paypal/maintenance";
    axiosRetry(axios, { retries: 3 });
    await axios
      .get(maintenanceEndpoint)
      .then(function (response) {
        setMaintenanceMessage(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const routeClinicalEncounters = () => {
    if (
      data?.applicantPortal?.caseDetails?.currentPathway === PATHWAYS.Pathway6 &&
      data?.applicantPortal?.caseDetails?.state !== "ApplicationCreation"
    ) {
      return (
        <>
          <Route path="/qualification6-encounter-management" element={<Qualification6AddEncounters.Component {...props} />} />

          <Route path="/miniCEXRequest" element={<PhysicianRequestLayout />} />
        </>
      );
    }
  };

  const HeaderLogo = () => {
    return screens.xs ? (
      <Header className="header-logo" style={{ alignContent: "center", height: "100%" }}>
        <Row style={{ height: "100%", paddingTop: "20px" }}>
          <Col span={24}>
            <Space direction={"vertical"} size={"small"} style={{ alignItems: "center", height: "100%" }}>
              <img
                src={ECFMGLogo}
                style={{
                  flex: 1,
                  height: "75%",
                  width: "100%",
                  resize: "block",
                  paddingLeft: 0,
                  float: "left",
                  minHeight: "100px",
                  maxWidth: "350px",
                }}
              />
              <Title
                level={1}
                style={{
                  textAlign: "center",
                  paddingLeft: "0",
                }}
              >
                2025 Pathways for ECFMG<sup>&reg;</sup> Certification and Pathways Revalidation
              </Title>
            </Space>
          </Col>
        </Row>
      </Header>
    ) : (
      <Header className="header-logo" style={{ alignContent: "center", height: "100%" }}>
        <Row style={{ height: "100%", paddingTop: "10px" }}>
          <Col span={24}>
            <Space direction={"horizontal"} size={50} style={{ alignItems: "center", height: "100%" }}>
              <img
                src={ECFMGLogo}
                style={{
                  flex: 1,
                  height: "75%",
                  width: "100%",
                  resize: "block",
                  paddingLeft: "25px",
                  float: "left",
                  minHeight: "100px",
                  minWidth: "300px",
                  maxWidth: "350px",
                }}
              />
              <Title
                level={1}
                style={{
                  textAlign: "right",
                  paddingLeft: "20px",
                }}
              >
                2025 Pathways for ECFMG<sup>&reg;</sup> Certification and Pathways Revalidation
              </Title>
            </Space>
          </Col>
        </Row>
      </Header>
    );
  };

  const revalidationRoutes = () => {
    return (
      <>
        <Route
          path="/redirect-after-login-revalidation"
          element={<RedirectAfterLoginRevalidation applicantPortal={data?.applicantPortal as ApplicantPortalResponseType} />}
        />
        <Route
          path="*"
          element={<RevalidationRoutes refetch={refetch} applicantPortalData={data?.applicantPortal as ApplicantPortalResponseType} />}
        />
      </>
    );
  };

  const getRoutings = () => {
    console.log("props.isAuthenticatedFakeForStorybook: " + props.isAuthenticatedFakeForStorybook);
    console.log(
      "data?.applicantPortal?.caseDetails?.isApplicationCreationAllowed: " + data?.applicantPortal?.caseDetails?.isApplicationCreationAllowed
    );
    if (isAuthenticated || props.isAuthenticatedFakeForStorybook) {
      if (data?.applicantPortal?.caseDetails?.applicationType === ApplicationTypeEnum.Revalidation) {
        return revalidationRoutes();
      } else if (data?.applicantPortal?.caseDetails?.isApplicationCreationAllowed)
        return (
          <>
            <Route path="/payment" element={<BillingInformation />} />

            <Route path="/viewpdf" element={<ViewPDF />} />
            <Route path="/documentUploader" element={<DocumentUploader />} />
            <Route
              path="/verification-ineligible"
              element={
                !!data?.applicantPortal?.pastAttempts && !!data?.applicantPortal?.eligibilityPreCheck ? (
                  <VerificationIneligible.Component
                    {...props}
                    pastAttempts={data.applicantPortal.pastAttempts as PastAttemptsType[]}
                    eligibilityPreCheck={data.applicantPortal.eligibilityPreCheck as EligibilityPreCheckResponseType}
                  />
                ) : null
              }
            />
            <Route
              path="/verification-eligible"
              element={
                !!data?.applicantPortal?.pastAttempts ? (
                  <VerificationEligible.Component {...props} pastAttempts={data.applicantPortal.pastAttempts as PastAttemptsType[]} />
                ) : null
              }
            />
            <Route
              path="/verification-eligible-step2cs-failed"
              element={
                !!data?.applicantPortal?.pastAttempts ? (
                  <VerificationEligibleStep2CSFailed.Component {...props} pastAttempts={data.applicantPortal.pastAttempts as PastAttemptsType[]} />
                ) : null
              }
            />
            {/* add restriction on routing */}
            {routingFlow !== RoutingFlow.Login_Pw6 ? (
              <>
                <Route
                  path="/qualificationA"
                  element={
                    !!data?.applicantPortal?.pathway1 ? (
                      <QualificationA.Component
                        {...props}
                        pathway1={data.applicantPortal.pathway1 as Pathway1}
                        refetch={() => {
                          refetch();
                        }}
                      />
                    ) : null
                  }
                />

                <Route
                  path="/qualificationB"
                  element={
                    !!data?.applicantPortal?.pathway1 ? (
                      <QualificationB.Component
                        {...props}
                        pathway1={data.applicantPortal.pathway1 as Pathway1}
                        refetch={() => {
                          refetch();
                        }}
                      />
                    ) : null
                  }
                />

                <Route
                  path="/qualificationE"
                  element={
                    !!data?.applicantPortal?.pathway2 ? (
                      <Qualification2b.Component
                        {...props}
                        pathway2={data.applicantPortal.pathway2 as Pathway2}
                        refetch={() => {
                          refetch();
                        }}
                      />
                    ) : null
                  }
                />
              </>
            ) : null}
            {routingFlow === RoutingFlow.Login_Pw12_2b_345 ||
            routingFlow === RoutingFlow.Login_Pw12_2b_345_6 ||
            routingFlow === RoutingFlow.Login_NotPw6 ? (
              <>
                <Route
                  path="/qualificationF"
                  element={
                    !!data?.applicantPortal?.pathway345 ? (
                      <QualificationF.Component
                        {...props}
                        pathway345={data.applicantPortal.pathway345 as Pathway345}
                        refetch={() => {
                          refetch();
                        }}
                      />
                    ) : null
                  }
                />
              </>
            ) : null}
            {/* add restriction on routing */}
            {routingFlow === RoutingFlow.Login_Pw6 ||
            routingFlow === RoutingFlow.Login_Pw12_2b_6 ||
            routingFlow === RoutingFlow.Login_Pw12_2b_345_6 ? (
              <>
                <Route
                  path="/Qualification6"
                  element={
                    !!data?.applicantPortal?.pathway6 ? (
                      <Qualification6.Component
                        {...props}
                        pathway6={data.applicantPortal.pathway6 as Pathway6}
                        refetch={() => {
                          refetch();
                        }}
                      />
                    ) : null
                  }
                />
              </>
            ) : null}

            {routeClinicalEncounters()}

            <Route path="/qualificationG" element={<QualificationG />} />
            <Route
              path="/qualificationI"
              element={
                <QualificationI.Component
                  {...props}
                  pathwayX={data?.applicantPortal?.pathwayX as ExceptionRequest}
                  refetch={() => {
                    refetch();
                  }}
                />
              }
            />
            <Route path="/qualification-review" element={<QualificationReview />} />
            <Route
              path="/qualification-complete"
              element={data?.applicantPortal ? <QualificationComplete {...props} applicantPortal={data.applicantPortal} /> : null}
            />
          </>
        );
    }
  };

  if (loading) return <Paragraph>Loading...</Paragraph>;

  if (error) {
    const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || "";
    message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
    return <Paragraph>Error :(</Paragraph>;
  }
  if (typeof isAuthenticated === "undefined") {
    return <Paragraph>auth check failed</Paragraph>;
  }

  return (
    <Layout className="layout">
      {isAuthenticated || props.isAuthenticatedFakeForStorybook ? null : <HeaderLogo />}

      <Layout>
        <HeaderContent
          // fakeValidTokenForStorybook={true}
          caseState={data?.applicantPortal?.caseDetails?.state}
          currentPathway={data?.applicantPortal?.caseDetails?.currentPathway}
          fakeValidTokenForStorybook={props.isAuthenticatedFakeForStorybook}
        />
        <Content className="site-layout" style={{ marginTop: "0px", display: "flex", flexDirection: "column" }}>
          <ScrollToTop />
          <Paragraph
            className="site-layout-background"
            style={{
              flex: "1 auto",
              display: "flex",
              flexFlow: "column",
              borderRadius: "0 0 0.8rem 0.8rem",
            }}
          >
            {maintenanceMessage?.data?.maintenanceType === maintenanceTypes.IMPENDING ||
            maintenanceMessage?.data?.maintenanceType === maintenanceTypes.ORACLE ? (
              <Alert message={maintenanceMessage.data?.maintenanceMsg} type="warning"></Alert>
            ) : null}

            {maintenanceMessage?.data?.maintenanceType === maintenanceTypes.SYSTEM ? (
              <Routes>
                <Route path="/" element={<SystemMaintenance {...props} maintenanceMessage={maintenanceMessage?.data?.maintenanceMsg} />} />
              </Routes>
            ) : (
              <>
                <SessionAlert />
                <Routes>
                  <Route path="/" element={<Login />} />

                  <Route path="/no-new-applications" element={<WarningPage />} />
                  <Route path="/login" element={<Login />} />

                  <Route path="/redirect-after-login-new" element={<RedirectAfterLoginNew {...props} applicantPortal={data?.applicantPortal} />} />
                  <Route path="/loggedOut" element={<LoggedOut />} />

                  {getRoutings()}
                </Routes>
              </>
            )}
          </Paragraph>
        </Content>
        <FooterLayout />
      </Layout>
    </Layout>
  );
};

export default App;
