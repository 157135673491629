import { UserOutlined } from "@ant-design/icons";
import { Button, Result, Row, Typography } from "antd";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { PastAttemptsType } from "../../../generated";
import { ApplicationHistory } from "./shared/application-history";
const { Link } = Typography;

interface RevalidationLandingProps {
  pastAttempts: PastAttemptsType[];
}
export const RevalidationLanding: FC<RevalidationLandingProps> = (props) => {
  const navigate = useNavigate();
  console.log("pastAttempts", props.pastAttempts);

  const onContinueClicked = () => {
    navigate(`/qualificationA`);
  };

  return (
    <>
      <Helmet>
        <title>Revalidation Eligible</title>
      </Helmet>
      <Result
        status="error"
        icon={<UserOutlined style={{ color: "#61C8D7" }} />}
        title={
          <>
            <Typography.Paragraph>
              <Typography.Title level={3}>You May Be Eligible to Pursue a 2025 Pathways Revalidation Application</Typography.Title>
              <Typography.Text style={{ fontSize: 14 }}>
                Please answer a series of questions to help determine your eligibility to pursue a 2025 Pathways Revalidation Application.
              </Typography.Text>
            </Typography.Paragraph>
          </>
        }
        extra={[
          <div style={{ maxWidth: "90%", margin: "0 auto 15px", textAlign: "left" }}>
            <Typography.Title level={4}>Important Notes:</Typography.Title>
            <Typography.Paragraph style={{ fontSize: 14, fontWeight: "normal" }}>
              Applicants with a previously accepted Pathways application from any Pathways season may be eligible to submit a Pathways Revalidation Application, 
              regardless of when their Pathway expires. However, please note that it is highly recommended that you complete the 2025 Pathways Revalidation Application <Typography.Text strong>only</Typography.Text> if:
              <br />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  you have an accepted 2021 or 2022 Pathway, or an ECFMG Certificate based on an accepted 2021 or 2022 Pathway, that will expire on December 31, 2024,
                   and you plan to participate in the 2025 NRMP Match
                </li>
              </ul>
              <ul style={{ listStyleType: "none"}}>
                <li>
                  <b>OR</b>
                </li>
              </ul>
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  you have an ECFMG Certificate based on a 2021 or 2022 Pathway that will expire on December 31, 2024, 
                  and you plan to enter a U.S. graduate medical education (GME) training program accredited by the Accreditation Council 
                  for Graduate Medical Education (ACGME) after December 31, 2024.  
                </li>
              </ul>

            </Typography.Paragraph>
            <Typography.Paragraph style={{ fontSize: 14, fontWeight: "normal" }}>
                If you are ECFMG certified and have already entered an ACGME-accredited training program or a non-standard program associated with an ACGME-accredited program, 
                and your ECFMG Certificate will expire before you complete the 12 months of clinical education required for your certificate to become valid indefinitely, 
                <Typography.Text strong> please{" "}
                <Link href={"https://www.ecfmg.org/contact.html"} target="_blank" rel="noreferrer">
                  contact us
                </Link>{" "}</Typography.Text>
                regarding the process for extending the validity of your ECFMG Certificate; <Typography.Text strong>do not use this Pathways Revalidation Application</Typography.Text> to extend the validity of your Certificate. See{" "}
                <Link href={"https://www.ecfmg.org/certification-pathways/permanent-validation.html"} target="_blank" rel="noreferrer">
                  Obtaining Permanent Validation of ECFMG Certificate
                </Link>{" "}for additional information.
              
            </Typography.Paragraph>
          </div>,
          <br />,
          <div style={{ maxWidth: "90%", margin: "0 auto", textAlign: "left" }}>
            <Typography.Text style={{ fontSize: 14 }}>
              ECFMG has determined that you currently meet the general eligibility requirements to pursue a Pathways Revalidation Application. You may
              continue with the application. Please note that if your eligibility changes after you submit this application, your application will be
              rejected, and you will not receive a refund.
            </Typography.Text>
            <br />
            <br />
            <Typography.Text style={{ fontSize: 14 }}>
              To determine your eligibility to apply for revalidation through Pathway 1 or Pathway 6, please answer the questions that follow and provide all requested
              information. Please be prepared to upload any required supporting documentation, including an acceptable English translation of any
              document not in English, as described in the{" "}
              <Link href={"https://www.ecfmg.org/certification-pathways/"} target="_blank" rel="noreferrer">
                Requirements for 2025 Pathways for ECFMG Certification
              </Link>
              .
            </Typography.Text>
            <br />
            <br />
            <Typography.Text style={{ fontSize: 14 }}>
              Please ensure that all the information you provide is complete and correct. Failure to provide complete and correct information can
              result in rejection of your application and/or a finding of{" "}
              <Link href={"https://www.ecfmg.org/resources/irregular-behavior.html"} target="_blank" rel="noreferrer">
                irregular behavior
              </Link>
              .
            </Typography.Text>
          </div>,
          <br />,
          <br />,
          <Button size="large" type="primary" onClick={() => onContinueClicked()}>
          Continue
        </Button>,
        ]}
      />
      <Row justify="center">{props.pastAttempts && props.pastAttempts.length > 0 && <ApplicationHistory pastAttempts={props.pastAttempts} />}</Row>
    </>
  );
};
