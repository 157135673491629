import dayjs from "dayjs";
import { FC } from "react";
import { PastAttemptsType } from "../../../../generated";
import { Table } from "antd";

interface ApplicationHistoryTableProps {
  pastAttempts: PastAttemptsType[];
}
export const ApplicationHistoryListDesktop: FC<ApplicationHistoryTableProps> = (props) => {
  const columns = [
    {
      title: "Pathway Season",
      dataIndex: "pathwaySeason",
      key: "pathwaySeason",
      align: "center",
    },
    {
      title: "Application Type",
      dataIndex: "applicationType",
      key: "applicationType",
      align: "center",
    },
    {
      title: "Submitted On",
      dataIndex: "submittedAt",
      key: "submittedAt",
      render: (text: string) => {
        return text ? dayjs(text).format("D-MMM-YYYY") : "N/A";
      },
      align: "center",
    },
    {
      title: "Review Completed On",
      dataIndex: "completedAt",
      key: "completedAt",
      render: (text: string) => {
        return text ? dayjs(text).format("D-MMM-YYYY") : "N/A";
      },
      align: "center",
    },
    {
      title: "Decision",
      dataIndex: "output",
      key: "output",
      align: "center",
    },
  ] as any;

  return <Table bordered columns={columns} dataSource={props.pastAttempts} pagination={false} />;
};
