import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Qualification6AddEncounters from "../../components/pathway-questions/qualification-6-encounter-management";
import { ApplicantPortalResponseType, EligibilityPreCheckResponseType, PastAttemptsType, Pathway1, Pathway6 } from "../../generated";
import Qualification6 from "../pathway-questions/qualification-6";
import QualificationA from "../pathway-questions/qualification-a";
import { RevalidationFreezePeriodPage } from "./pages/revalidation-freeze-period-page";
import { RevalidationLandingPage } from "./pages/revalidation-landing-page";

import BillingInformation from "../BillingInformation";
import { PATHWAYS } from "../constants";
import QualificationB from "../pathway-questions/qualification-b";
import PhysicianRequestLayout from "../physician-request/physician-request-layout";
import QualificationComplete from "../qualification-complete";
import QualificationReview from "../qualification-review/qualification-review";
import { RevalidationVerificationEligibleStep2CSFailedPage } from "./pages/revalidation-verification-eligible-step2cs-failed-page";
import { RevalidationVerificationIneligiblePage } from "./pages/revalidation-verification-ineligible-page";

interface RevalidationRoutesProps {
  applicantPortalData?: ApplicantPortalResponseType;
  refetch: () => void;
}
export const RevalidationRoutes: FC<RevalidationRoutesProps> = (props) => {
  const getClinicalEncountersRoutes = () => {
    if (
      props?.applicantPortalData?.caseDetails?.currentPathway === PATHWAYS.Pathway6 &&
      props?.applicantPortalData?.caseDetails?.state !== "ApplicationCreation"
    ) {
      return (
        <>
          <Route path="/qualification6-encounter-management" element={<Qualification6AddEncounters.Component {...props} />} />
          <Route path="/miniCEXRequest" element={<PhysicianRequestLayout />} />
        </>
      );
    }
  };

  const getCommonRoutes = () => {
    return (
      <>
        <Route
          path="/revalidation-verification-eligible"
          element={<RevalidationLandingPage pastAttempts={props.applicantPortalData?.pastAttempts as PastAttemptsType[]} />}
        />
        <Route path="/payment" element={<BillingInformation />} />
        <Route
          path="/qualification-review"
          element={<QualificationReview applicationType={props.applicantPortalData?.caseDetails?.applicationType as string} />}
        />
        <Route
          path="/qualification-complete"
          element={props.applicantPortalData ? <QualificationComplete {...props} applicantPortal={props.applicantPortalData} /> : null}
        />
      </>
    );
  };

  const getRoutes = () => {
    if (props.applicantPortalData?.caseDetails?.isApplicationCreationAllowed) {
      if (props.applicantPortalData?.eligibilityPreCheck?.eligibility?.isEligible) {
        if (props.applicantPortalData?.eligibilityPreCheck?.isStep2CSFailed) {
          return (
            <>
              <Route
                path="/revalidation-verification-eligible-step2cs-failed"
                element={
                  <RevalidationVerificationEligibleStep2CSFailedPage pastAttempts={props.applicantPortalData.pastAttempts as PastAttemptsType[]} />
                }
              />
              <Route
                path="/qualification6"
                element={
                  <Qualification6.Component
                    pathway6={props.applicantPortalData?.pathway6 as Pathway6}
                    refetch={() => {
                      props.refetch();
                    }}
                  />
                }
              />
              {getClinicalEncountersRoutes()}
              {getCommonRoutes()}
            </>
          );
        }
        return (
          <>
            <Route
              path="/qualificationA"
              element={<QualificationA.Component refetch={props.refetch} pathway1={props.applicantPortalData?.pathway1 as Pathway1} />}
            />
            <Route
              path="/qualificationB"
              element={
                <QualificationB.Component
                  refetch={props.refetch}
                  pathway1={props.applicantPortalData?.pathway1 as Pathway1}
                  applicationType={props.applicantPortalData?.caseDetails?.applicationType as string}
                />
              }
            />
            <Route
              path="/qualification6"
              element={
                <Qualification6.Component
                  pathway6={props.applicantPortalData?.pathway6 as Pathway6}
                  refetch={() => {
                    props.refetch();
                  }}
                />
              }
            />
            {getClinicalEncountersRoutes()}
            {getCommonRoutes()}
          </>
        );
      } else {
        return (
          <>
            <Route
              path="/revalidation-verification-ineligible"
              element={
                <RevalidationVerificationIneligiblePage
                  eligibilityPreCheck={props.applicantPortalData.eligibilityPreCheck as EligibilityPreCheckResponseType}
                  pastAttempts={props.applicantPortalData?.pastAttempts as PastAttemptsType[]}
                />
              }
            />
          </>
        );
      }
    } else {
      return (
        <Route
          path="/revalidation-freeze-period"
          element={<RevalidationFreezePeriodPage pastAttempts={props.applicantPortalData?.pastAttempts as PastAttemptsType[]} />}
        />
      );
    }
  };

  return <Routes>{getRoutes()}</Routes>;
};
