import { Descriptions, Space } from "antd";
import dayjs from "dayjs";
import { FC } from "react";
import { PastAttemptsType } from "../../../../generated";

const ApplicationHistoryItemCard: FC<{ pastAttempt: PastAttemptsType }> = (props) => {
  return (
    <Descriptions layout="vertical" labelStyle={{fontWeight:"600"}} style={{border:"1px solid #f0f0f0", padding:"5px"}}>
      <Descriptions.Item label="Pathway Season">{props.pastAttempt.pathwaySeason}</Descriptions.Item>
      <Descriptions.Item label="Application Type">{props.pastAttempt.applicationType}</Descriptions.Item>
      <Descriptions.Item label="Submitted On">
        {props.pastAttempt.submittedAt ? dayjs(props.pastAttempt.submittedAt).format("D-MMM-YYYY") : "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Review Completed On">
        {props.pastAttempt.completedAt ? dayjs(props.pastAttempt.completedAt).format("D-MMM-YYYY") : "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Decision">{props.pastAttempt.output}</Descriptions.Item>
    </Descriptions>
  );
};

interface ApplicationHistoryMobileProps {
  pastAttempts: PastAttemptsType[];
}

export const ApplicationHistoryListMobile: FC<ApplicationHistoryMobileProps> = (props) => {
  return (
    <Space direction="vertical">
      {props.pastAttempts.map((pastAttempt) => (
        <ApplicationHistoryItemCard pastAttempt={pastAttempt} />
      ))}
    </Space>
  );
};
