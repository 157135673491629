import { WarningOutlined } from "@ant-design/icons";
import { Row, Typography } from "antd";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { PastAttemptsType } from "../../../generated";
import { ApplicationHistory } from "../components/shared/application-history";

interface RevalidationFreezePeriodPageProps {
  pastAttempts: PastAttemptsType[];
}
export const RevalidationFreezePeriodPage: FC<RevalidationFreezePeriodPageProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Revalidation Freeze</title>
      </Helmet>
      <div
        style={{
          marginTop: "100px",
          alignSelf: "center",
          width: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WarningOutlined style={{ color: "#FAAD14", fontSize: "100px", marginBottom: "30px" }} />
        <Typography.Text style={{ marginBottom: "80px" }}>No Pathways Revalidation applications can be created at this time.</Typography.Text>
      </div>
      <Row justify="center">
        <ApplicationHistory pastAttempts={props.pastAttempts} />
      </Row>
    </>
  );
};
