import { FC } from "react";
import { PastAttemptsType } from "../../../generated";
import { RevalidationLanding } from "../components/revalidation-landing";

interface RevalidationLandingPageProps {
  pastAttempts: PastAttemptsType[];
}
export const RevalidationLandingPage: FC<RevalidationLandingPageProps> = (props) => {
  return <RevalidationLanding pastAttempts={props.pastAttempts as PastAttemptsType[]} />;
};
