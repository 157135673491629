import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Alert, Button, Col, Collapse, Divider, Form, Grid, Input, Row, Space, Spin, Typography, message } from "antd";
import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ApplicationTypeEnum } from "../App";
import { ApplicantLoginPortalLoginDocument } from "../generated";
import { ResetPasswordLink } from "../util/environmentVariables";
import useAuth from "./auth/use-auth";
const { Title, Paragraph, Link, Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const Login: FC<any> = () => {
  const navigate = useNavigate();
  const [userid, setUserID] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loginAction] = useMutation(ApplicantLoginPortalLoginDocument);

  const { setAuthToken, logout } = useAuth();
  const screens = useBreakpoint();
  const handleLogin = async () => {
    if (userid?.length !== 8) {
      message.warning("8 digits, no dashes");
    } else {
      setIsProcessing(true);
      logout();
      await loginAction({
        variables: {
          userid: userid?.trim(),
          password: password ?? "",
        },
      })
        .then((data) => {
          console.log("LOGIN: Success, received token", data?.data?.ApplicantPortal_login?.token);
          setAuthToken(data?.data?.ApplicantPortal_login?.token as string);
          console.log("LOGIN: going to push history");

          // Revalidation application
          if (data?.data?.ApplicantPortal_login?.case?.applicationType === ApplicationTypeEnum.Revalidation) {
            if (data?.data?.ApplicantPortal_login?.case?.isApplicationCreationAllowed) {
              navigate(`/redirect-after-login-revalidation`);
            } else {
              navigate(`/revalidation-freeze-period`);
            }
          }
          // New application
          else {
            if (data?.data?.ApplicantPortal_login?.case?.isApplicationCreationAllowed) {
              navigate(`/redirect-after-login-new`);
            } else {
              navigate(`/no-new-applications`);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          //networkError only comes when there is a network error: IE: graphql being down
          if (error.networkError?.toString().includes("Failed to fetch")) {
            setErrorMessage("System Error: Please contact ECFMG’s Applicant Information Services");
          } else {
            var graphQLErrorReference;

            //only grab the first error
            graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || "";
            setErrorMessage(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
          }

          setIsProcessing(false);
          setLoginFailed(true);
        });
    }
  };

  const onUsernameChange = (username: string) => {
    const strippedUserId = username.replace(/\D/g, "").trim();
    form.setFieldsValue({ login: strippedUserId });
    setUserID(strippedUserId);
    setErrorMessage(undefined);
    setLoginFailed(false);
  };

  const onPasswordChange = (password: string) => {
    setErrorMessage(undefined);
    setPassword(password);
    setLoginFailed(false);
  };
  const [form] = Form.useForm();

  return (
    <>
      <Helmet>
        <title>Certification Pathways</title>
      </Helmet>

      <div style={{ textAlign: "center", margin: "0" }}>
        <Title level={2}>Application</Title>
      </div>
      <Divider />
      <Row>
        <Col xs={24} md={12} lg={8}>
          <Paragraph className={"alignRightMedium"}>
            <Paragraph>
              <Title level={5} style={{ paddingBottom: "15px" }}>
                Please log in using your USMLE/ECFMG ID and On-line Services password.
              </Title>
              {loginFailed ? <Alert message={errorMessage} type="error"></Alert> : <Paragraph></Paragraph>}

              <Paragraph style={{ textAlign: "center" }}>
                <Paragraph className={"loginForm"}>
                  <Form
                    form={form}
                    onFinish={handleLogin}
                    layout={screens.xs ? "vertical" : "horizontal"}
                    // layout="inline"
                  >
                    <Form.Item name="login" label="User ID" initialValue={userid} rules={[{ required: true, message: "Enter Username" }]}>
                      <Input
                        className={"loginElement"}
                        style={{ margin: "3px" }}
                        value={userid}
                        onChange={(e) => onUsernameChange(e.target.value)}
                        onPressEnter={() => handleLogin()}
                        placeholder="USMLE/ECFMG ID"
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item name="password" label="Password" rules={[{ required: true, message: "Enter Password" }]} style={{ margin: "0 0 0 0" }}>
                      <Input.Password
                        className={"loginElement"}
                        style={{ margin: "3px" }}
                        value={password}
                        onChange={(e) => onPasswordChange(e.target.value)}
                        onPressEnter={() => handleLogin()}
                        allowClear
                      />
                    </Form.Item>
                    <Link href={ResetPasswordLink()} style={{ marginBottom: "24px", display: "block" }} target="_blank" rel="noreferrer">
                      Forgot your password?
                    </Link>

                    <Paragraph style={{ textAlign: screens.xs ? "center" : "right" }}>
                      <Button size="large" style={{ margin: "3px" }} type="primary" disabled={isProcessing} htmlType="submit">
                        {isProcessing ? (
                          <>
                            <Spin size="small" style={{ paddingRight: "20px" }} /> Processing...
                          </>
                        ) : (
                          <>Login</>
                        )}
                      </Button>
                    </Paragraph>
                  </Form>
                </Paragraph>
              </Paragraph>
            </Paragraph>
          </Paragraph>
        </Col>
        <Col xs={0} md={1}>
          <Paragraph
            style={{
              paddingLeft: "50%",
              marginRight: "50%",
              minHeight: "100%",
              borderRight: "1px solid black",
            }}
          ></Paragraph>
        </Col>
        <Col xs={24} md={0}>
          <Paragraph style={{ paddingTop: "30px" }}></Paragraph>
        </Col>
        <Col xs={24} md={11} lg={15}>
          <Paragraph>
            This application is for international medical graduates to: 
            <>
            <ul style={{ listStyleType: "disc" }}>
              <li>
              meet the clinical and communication skills requirements for ECFMG Certification as described in the{" "}
            <Link href={"https://www.ecfmg.org/certification-pathways/"} target={"_blank"} rel="noreferrer">
              {" "}
              Requirements for 2025 Pathways for ECFMG Certification
            </Link>{" "}
            section of the ECFMG website
              </li>
              </ul>
              <ul style={{ listStyleType: "none"}}>
                <li>
                  <b>OR</b>
                </li>
              </ul>
              <ul style={{ listStyleType: "disc" }}>
              <li>
              revalidate an accepted Pathway that is expired or due to expire, as described on the{" "}
            <Link href={"https://www.ecfmg.org/certification-pathways/expiration.html"} target={"_blank"} rel="noreferrer">
            Information on Expiration of Pathways and ECFMG Certificate
            </Link>{" "}
            page of the ECFMG website.
              </li>
            </ul>
            </>
          </Paragraph>
          <Collapse ghost>
            <Panel
              header={
                <Space>
                  <InfoCircleOutlined /> Important Information
                </Space>
              }
              key="instructions"
            >
              <Paragraph
                style={{
                  textAlign: "initial",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                <Paragraph>
                  Before you begin this application:
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                    If you are applying to the Pathways to meet the clinical and communication skills requirements for ECFMG Certification 
                    (i.e., you are not revalidating a previously accepted Pathways application), 
                    you must read the{" "}
                    <Link href={"https://www.ecfmg.org/certification-pathways/"} target={"_blank"} rel="noreferrer">
                      {" "}
                      Requirements for 2025 Pathways for ECFMG Certification
                    </Link>{" "}
                    section of the website carefully, including the eligibility requirements and detailed instructions. 
                    </li>
                    <li>
                      If you are applying to revalidate a previously accepted Pathway, 
                      you must read the{" "}
                      <Link href={"https://www.ecfmg.org/certification-pathways/expiration.html"} target={"_blank"} rel="noreferrer">
                      Information on Expiration of Pathways and ECFMG Certificate
                      </Link>{" "}
                      page on the ECFMG website, as well as other applicable Pathways information and requirements.
                    </li>
                  </ul>
                </Paragraph>
                <Paragraph>
                  If you submit this application
                  and ECFMG determines that you are ineligible for the Pathways or revalidation, your application will be rejected, and{" "}
                  <Text strong>the application fee will not be refunded.</Text>
                </Paragraph>
                <Paragraph>
                  Once you begin your application, the information will be saved for five days. If you do not submit the application within that time,
                  you will be required to begin a new application.
                </Paragraph>
                <Paragraph>
                  Full payment is due when you submit your application. You must use a Visa, MasterCard, Discover, or American Express with a security
                  code; credit available in your ECFMG Financial Account cannot be used to pay for this application.
                </Paragraph>
                <Paragraph>Once your application has been submitted, you can use this website to check the status of your application.</Paragraph>
              </Paragraph>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default Login;
