import { Button, Checkbox, Form, Row, Space, Typography } from "antd";
import { FC, useState } from "react";
const { Title, Paragraph, Link } = Typography;

export interface RevalidationQualificationReviewAttestationProps {
  onCancelCallback: () => void;
  onContinueCallback: () => void;
}

export const RevalidationQualificationReviewAttestation: FC<RevalidationQualificationReviewAttestationProps> = ({
  onCancelCallback,
  onContinueCallback,
}) => {
  const [form] = Form.useForm();
  const [attested, setAttested] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  return (
    <Form form={form} onFinish={onContinueCallback}>
      <Title level={5} style={{ fontWeight: 600 }}>
        2025 Pathways Revalidation Application
      </Title>
      <Paragraph>
        I certify that I have read and understand the{" "}
        <Link href="https://www.ecfmg.org/certification-pathways/expiration.html" target="_blank" rel="noreferrer">
          Information on Expiration of Pathways and ECFMG Certificate
        </Link>, including the{" "}
        <Link href="https://www.ecfmg.org/certification-pathways/expiration.html#reval" target="_blank" rel="noreferrer">
          Pathways Revalidation
        </Link>{" "}section and, to the best of my knowledge, I meet all eligibility requirements for the Pathways Revalidation Application. I understand and agree
        that submission of this application does not guarantee that my application will be approved or that I will become ECFMG certified, if I am not
        already certified. I further understand and agree that ECFMG does not have control over the time that it takes to primary-source verify the
        information in this application or the documents submitted in support of it. I understand and agree that decisions on selection of residency
        applicants is at the sole discretion of the residency program.
      </Paragraph>
      <Paragraph>
        I understand that if my 2025 Pathways Revalidation Application is accepted, the revalidated Pathway is subject to expiration.
         I further understand that if I become ECFMG certified based on a revalidated Pathway,
          my ECFMG Certificate will expire when the revalidated Pathway expires,
           unless I meet requirements to have my ECFMG Certificate made{" "}
        <Link href="https://www.ecfmg.org/certification-pathways/permanent-validation.html" target="_blank" rel="noreferrer">
        valid indefinitely
        </Link>{" "}.
      </Paragraph>
      <Paragraph>
        I understand that a valid, unexpired ECFMG Certificate is required for entry into a U.S. graduate medical education (GME) training program accredited
         by the Accreditation Council for Graduate Medical Education (ACGME). I further understand that if I do not enter an ACGME-accredited training program
         by the time my ECFMG Certificate expires, and I wish to enter an ACGME-accredited training program in the future, I will be required to meet
         the clinical and communication skills requirements for ECFMG Certification in place at that time in order to revalidate my ECFMG Certificate.
      </Paragraph>
      <Title level={5} style={{ fontWeight: 600 }}>
        Irregular Behavior
      </Title>
      <Paragraph>
        I certify that I have read and understand the{" "}
        <Link href="https://www.ecfmg.org/resources/irregular-behavior.html" target="_blank" rel="noreferrer">
          Policies and Procedures Regarding Irregular Behavior
        </Link>{" "}
        and agree to abide by these policies and procedures. I certify I understand that, as provided in the Policies and Procedures Regarding
        Irregular Behavior, among other things, ECFMG may find that submission of false information or falsified documents to ECFMG constitutes
        irregular behavior, which could result in actions including suspension or revocation of, or permanent bar to ECFMG Certification; suspension
        or removal of J-1 Visa sponsorship; and/or a report of a determination of irregular behavior to any organization or individual who, in the
        judgment of ECFMG, has a legitimate interest in such information. (Organizations with a legitimate interest in such information include the
        USMLE Committee for Individualized Review; Federation of State Medical Boards of the United States; any applicable federal, state, local, or
        other relevant governmental or regulatory department or agency; U.S. state and international medical licensing authorities; and graduate
        medical education programs.)
      </Paragraph>
      <Title level={5} style={{ fontWeight: 600 }}>
        Release of Information Authorization
      </Title>
      <Paragraph>
        I hereby request and authorize every person, medical school, medical regulatory authority, university, hospital, government agency, or other
        entity to release any documents and information to ECFMG, a division of Intealth, bearing on the content of my application or other request
        related to services that I have asked ECFMG to provide to me including, but not limited to, records, diplomas, transcripts,
        licenses/registrations, and other documents concerning my identity, citizenship or immigration status, educational, academic or professional
        history and status, or enrollment.
      </Paragraph>
      <Paragraph>
        I hereby request and authorize ECFMG to transmit any documents or information in its possession, or that may otherwise become available to
        ECFMG, bearing on the content of my application or other request related to services that I have requested ECFMG provide to me including, but
        not limited to, records, diplomas, transcripts, licenses/registrations, and other documents concerning my identity, citizenship or immigration
        status, educational, academic or professional history and status, or enrollment, where such disclosure is necessary for ECFMG to provide the
        requested services or to any other organization or individual who, in the sole judgment of ECFMG, has a legitimate interest in such
        information.
      </Paragraph>
      <Paragraph>
        I also extend absolute immunity to, and release, other agencies, medical schools, institutions, hospitals and clinics, and registration and
        licensing authorities providing information, their employees, contractors representatives, directors, and officers, and any third parties and
        organizations for their acts, communications, reports, records, diplomas, transcripts, statements, documents, recommendations, or disclosures
        involving me, made in good faith and without malice, requested by ECFMG.
      </Paragraph>
      <Paragraph>
        I confirm that I have read, understood, and agree to the terms of the Release of Information Authorization as described above and agree to be
        legally bound by them.
      </Paragraph>
      <Title level={5} style={{ fontWeight: 600 }}>
        Privacy Notice and Consent
      </Title>
      <Paragraph>
        Information regarding how ECFMG, a division of Intealth, may collect, use, and disclose my personal information in connection with the
        services offered by ECFMG is set forth in the Intealth Privacy Notice, available on the Intealth website at{" "}
        <Link href="https://www.intealth.org/privacy/" target="_blank" rel="noopener noreferrer">
          https://www.intealth.org/privacy.
        </Link>
      </Paragraph>
      <Form.Item
        name="privacyNotice"
        valuePropName="checked"
        rules={[
          {
            required: true,
            transform: (value) => value || undefined,
            type: "boolean",
            message:
              "You must consent to the collection and processing of your personally identifiable information in accordance with ECFMG’s Privacy Notice in order to continue with your Pathways Revalidation Application.",
          },
        ]}
      >
        <Checkbox id="privacy-checkbox" checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)}>
          By checking this box, I certify that I have read, understood, and agree to the applicable terms of the Intealth Privacy Notice and consent
          to the collection and processing of my personally identifiable information in connection with my Pathways application and in accordance with
          the Intealth Privacy Notice.
        </Checkbox>
      </Form.Item>

      <Title level={5} style={{ fontWeight: 600 }}>
        Pathways Revalidation Application Attestation
      </Title>
      <Form.Item
        name="attested"
        valuePropName="checked"
        rules={[
          {
            required: true,
            transform: (value) => value || undefined,
            type: "boolean",
            message:
              "You must certify that the information in this application is accurate and was provided solely by you, and that you have read and understand all the statements above, in order to continue with your Pathways Revalidation Application. ",
          },
        ]}
      >
        <Checkbox id="attest-checkbox" checked={attested} onChange={(e) => setAttested(e.target.checked)}>
          By checking this box, I hereby certify that the information in this application was provided solely by me and is true and correct to the
          best of my knowledge; <b>and</b> I hereby certify that I have read, understood, and agree to all of the statements in the 2025 Pathways Revalidation
           Application, Irregular Behavior, and Release of Information Authorization.
        </Checkbox>
      </Form.Item>
      <Row className={"alignRightMedium"}>
        <Paragraph style={{ textAlign: "right" }}>
          <Space size="small">
            <Button size="large" id="qrev-back-button" style={{ margin: "3px" }} type="default" onClick={onCancelCallback}>
              Cancel
            </Button>
            <Button size="large" id="qrev-continue-button" htmlType="submit" type="primary">
              Continue
            </Button>
          </Space>
        </Paragraph>
      </Row>
    </Form>
  );
};
