import { useMutation } from "@apollo/client/react";
import { Button, Col, Row, Space, Typography, message } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import PropTypes, { InferProps } from "prop-types";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useMatch, useNavigate, useNavigationType } from "react-router-dom";
import { ApplicantQualification6ApplicantPortal_Pathway6Document, Pathway6 } from "../../generated";

const { Title, Paragraph, Link, Text } = Typography;
const ComponentPropTypes = {
  pathway6: PropTypes.shape({
    userResponse: PropTypes.shape({
      hasAgreedToProvidePhysicianInfo: PropTypes.bool,
    }),
    applicantProvidedData: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        data: PropTypes.shape({
          acknowledgedTimestamp: PropTypes.number,
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              docType: PropTypes.string,
              title: PropTypes.string,
              docId: PropTypes.string,
            })
          ),
        }),
      })
    ),
  }),
  refetch: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "Pathway6";
  pathway6: Pathway6;
  refetch: Function;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const Qualification6: FC<ComponentProps> = ({ pathway6, refetch }) => {
  const [attested, setAttested] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [UpdatePathway6] = useMutation(ApplicantQualification6ApplicantPortal_Pathway6Document);

  //update UI
  useEffect(() => {
    //Handles if back button is pushed. Reload the page
    if (navigationType === "POP") {
      navigate(location.pathname);
      refetch();
    }
  }, [pathway6]);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of screen on page load
  }, []);

  const onContinue = async () => {
    if (attested) {
      return UpdatePathway6()
        .then((data) => {
          navigate(`/Qualification-review`);
        })
        .catch((error) => {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || "";
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    }
  };

  const onCheckboxClicked = (e: any) => {
    setAttested(e.target.checked);
  };

  return (
    <>
      <Helmet>
        <title>Pathway 6</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={4}>Pathway 6: Evaluation of Clinical Patient Encounters by Licensed Physicians</Title>
        </Col>
      </Row>
      <Paragraph>
        Pathway 6 is for the following individuals:
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Pathways applicants who do not meet the eligibility requirements for Pathway 1, 2, 3, 4, or 5 and/or have failed Step 2 CS one or more
          times;
          </li>
        </ul>
        <ul style={{ listStyleType: "none"}}>
          <li>
            <b>OR</b>
          </li>
        </ul>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Revalidation applicants with an accepted Pathway that is expired or due to expire who do not meet the eligibility requirements for Pathway 1 and/or have
            failed Step 2 CS one or more times
          </li>
        </ul>
      </Paragraph>
      <Paragraph>To meet the requirements for Pathway 6:</Paragraph>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          Your clinical skills must be observed and evaluated by licensed physicians in six real, in-person clinical encounters using ECFMG’s
          Mini-Clinical Evaluation Exercise (Mini-CEX) for Pathway 6.
          <ul style={{ listStyleType: "circle" }}>
            <li>
              Each encounter must be evaluated by an individual licensed physician who meets the{" "}
              <Link target="_blank" href="https://www.ecfmg.org/certification-pathways/pathway-6.html#physician-evals" rel="noreferrer">
                Criteria for Acceptable Physician Evaluators
              </Link>{" "}
              outlined on the Pathway 6 page on the ECFMG website.
            </li>
            <li>
              Each encounter must meet the{" "}
              <Link target="_blank" href="https://www.ecfmg.org/certification-pathways/pathway-6.html#clinical-encounters" rel="noreferrer">
                Criteria for Acceptable Clinical Encounters
              </Link>{" "}
              outlined on the Pathway 6 page on the ECFMG website.
            </li>
            <li>Each individual physician evaluator may evaluate no more than two of your six encounters.</li>
          </ul>
        </li>
        <li>
          An electronic ECFMG Mini-CEX for Pathway 6 must be submitted to ECFMG for each clinical encounter through ECFMG’s Clinical Skills Evaluation
          and Attestation Portal by the evaluating physician.
        </li>
        <li>
          For all six clinical encounters, you must obtain an{" "}
          <Link href="https://www.ecfmg.org/certification-pathways/pathway-6.html#scoring" target="_blank" rel="noreferrer">
            acceptable score
          </Link>{" "}
          in each of the four clinical skills components evaluated using the Mini-CEX for Pathway 6: Medical Interviewing Skills, Physical Examination
          Skills, Professionalism/Communication Skills, and Clinical Reasoning &amp; Judgment.
        </li>
      </ul>

      <Paragraph>
        In addition to the requirements listed above, you must meet applicable requirements detailed in the{" "}
        <Link target="_blank" href="https://www.ecfmg.org/certification-pathways/" rel="noreferrer">
          Pathways
        </Link>
          {" "}section of the ECFMG website, including those detailed on the{" "}
        <Link target="_blank" href="https://www.ecfmg.org/certification-pathways/oet.html" rel="noreferrer">
          Assessment of Communication Skills, Including English Language Proficiency
        </Link>
        {" "}page.
      </Paragraph>
      <Paragraph>
        Before you submit your application, you must read the entire{" "}
        <Link target="_blank" href="https://www.ecfmg.org/certification-pathways/pathway-6.html" rel="noreferrer">
          Pathway 6
        </Link>{" "}
        page on the ECFMG website carefully, including the Eligibility Requirements, Criteria for Acceptable Physician Evaluators, Criteria for
        Acceptable Clinical Encounters, and all Application Instructions, deadlines, and related information.{" "}
        <Text strong>It is your responsibility to ensure that all of your physician evaluators and clinical encounters meet ECFMG’s criteria. </Text>
        If you submit this application and do not complete all requirements for Pathway 6, or if ECFMG determines that you are ineligible for Pathway
        6, your application will be rejected, and <Text strong>the application fee will not be refunded</Text>. In addition, if ECFMG does not receive
        all required documentation in support of your application, including all six Mini-CEX evaluations, your application will not be complete, and{" "}
        <Text strong>the application fee will not be refunded</Text>.
      </Paragraph>
      <Paragraph>
        <Space direction="vertical">
          <Checkbox checked={attested} onChange={(e) => onCheckboxClicked(e)}>
            I certify that I have read and understand all requirements for Pathway 6.
          </Checkbox>
          {/* <label hidden={!displayErrorMessage} style={{ color: "red" }}>
              Please check the checkbox.
            </label> */}
        </Space>
      </Paragraph>

      <Paragraph>
        After you submit your application, you will be required to submit the names and e-mail addresses of your physician evaluators through this
        application portal. Please note that you must obtain approval from your physician evaluators to provide their names and e-mail addresses to
        ECFMG <Text strong>before</Text> you submit this information.
      </Paragraph>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            id="q6-continue-button"
            type="primary"
            size="large"
            disabled={!attested}
            onClick={() => {
              onContinue();
            }}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default {
  Component: Qualification6,
};
